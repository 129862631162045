import React from 'react';
import { useState, useEffect } from "react";
import './App.css';

function App(props) {
  const [windowSize, setWindowSize] = useState({width: document.body.clientWidth, height: document.body.clientHeight});

  const windowResized = e => {
    setWindowSize({width: document.body.clientWidth, height: document.body.clientHeight});
  }

  useEffect(() => {
    window.addEventListener("resize", windowResized);
  
    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, []);

  return (
    <div style={{padding: "4px", float:"left"}}>Window width: {windowSize.width}<br />Window height: {windowSize.height}<br />FPS: {props.fps}</div>
  );
}

export default App;
