import { vec3, vec2, mat4, mat3 } from 'gl-matrix';
import { GlassContext, Screen, Offscreen, Transform, TEXTURE_TYPES, ERROR_CHECKING, DepthCopyRenderer } from '@ancienttech/glass';
import GBufferRenderer from './renderers/gbuffer';
import { debugModes, DebugRenderer } from '@ancienttech/glass';
import ProtagonistRenderer from './renderers/protagonist';
import ProtagonistDepthRenderer from './renderers/protagonistdepth';
import PlanetRenderer from './renderers/planet';
import FOWRenderer from './renderers/fow';
import DepthSquasherRenderer from './renderers/depthsquasher';
import FOWClearerRenderer from './renderers/fowclearer';
import FOWDarkeningRenderer from './renderers/lighting/fowdarkening';
import GlobalLightingRenderer from './renderers/lighting/globallighting';
import LocalLightingRenderer from './renderers/lighting/locallighting';
import LineOfSightRenderer from './renderers/lighting/lineofsight';

export default class GLEngine {
    constructor(canvasElement) {
        this.context = new GlassContext(canvasElement, ERROR_CHECKING.ALL);
        this.properties = {};
        this.currentPipelineIndex = 0;
    }

    async initAsync() {
        const povDepthSize = { width: 2048, height: 256 };
        const povProjectionNear = 0.1;
        const povProjectionFar = 10.0;

        const blenderExportTransformMatrix = new Transform()
            .queueFixedScaling(0.1, 0.1, 0.1)
            .queueFixedXRotation(Math.PI/2.0)
            .queueFixedZRotation(-Math.PI/2.0)
            .getMatrix(0, {});
        const entryHallMesh = await this.context.loadWavefrontObject(require('./objects/entryhall.obj'), blenderExportTransformMatrix);
        const entryHallLightVolumeMesh = await this.context.loadWavefrontObject(require('./objects/entryhall.lightvolume.obj'), blenderExportTransformMatrix);

        const protagonistCommonViewTransform = new Transform()
            .queueOperation((delta, sinceStart, properties) => mat4.fromTranslation(mat4.create(), vec3.negate(vec3.create(), properties.positions.protagonist)))
            .queueFixedXRotation(-Math.PI * 0.5);
        const protagonistCommonProjectionTransform = new Transform().queueMatrix(mat4.perspective(mat4.create(), Math.PI * 0.5, 1.0, povProjectionNear, povProjectionFar));
        const protagonistCameraTransforms = [
            { view: protagonistCommonViewTransform, projection: protagonistCommonProjectionTransform },
            { view: new Transform().queueOtherTransform(protagonistCommonViewTransform).queueFixedYRotation(Math.PI * 0.5), projection: protagonistCommonProjectionTransform },
            { view: new Transform().queueOtherTransform(protagonistCommonViewTransform).queueFixedYRotation(Math.PI * 1.0), projection: protagonistCommonProjectionTransform },
            { view: new Transform().queueOtherTransform(protagonistCommonViewTransform).queueFixedYRotation(Math.PI * 1.5), projection: protagonistCommonProjectionTransform }
        ];

        const protagonistDepths = protagonistCameraTransforms.map(transform =>
            new Offscreen(this.context, [], true, [0, 0, 0, 1, 1.0], povDepthSize.width, povDepthSize.height)
                .addSubRenderer("depth", new ProtagonistDepthRenderer(this.context, entryHallMesh, transform))
        );

        const squashedDepths = protagonistDepths.map(depth =>
            new Offscreen(this.context, [], true, [0, 0, 0, 1, 1.0], povDepthSize.width, povDepthSize.height)
                .addSubRenderer("squash", new DepthSquasherRenderer(this.context, povDepthSize.width, povDepthSize.height)
                    .addOffscreen("depth", depth)
                )
        );

        const gbuffer = new Offscreen(this.context, [TEXTURE_TYPES.UBYTE_RGBA, TEXTURE_TYPES.FLOAT32_RGBA, TEXTURE_TYPES.FLOAT16_RG, TEXTURE_TYPES.FLOAT32_RGBA], true, [0, 0, 0, 1, 1.0])
            .addSubRenderer("level", new GBufferRenderer(this.context, entryHallMesh))
            .addSubRenderer("planets", new PlanetRenderer(this.context));

        const fow = new Offscreen(this.context, [{ ...TEXTURE_TYPES.UBYTE_RGBA, magFilter: "LINEAR"}], false, [undefined, undefined, undefined, undefined, 1.0], 4096, 4096)
            .addSubRenderer("processedFow", new FOWClearerRenderer(this.context)
                .addOffscreen("input", new Offscreen(this.context, [{ ...TEXTURE_TYPES.UBYTE_RGBA, magFilter: "LINEAR"}], false, [undefined, undefined, undefined, undefined, 1.0], 4096, 4096)
                    .addSubRenderer("fow", new FOWRenderer(this.context)
                        .addOffscreen("povDepthN", squashedDepths[0])
                        .addOffscreen("povDepthE", squashedDepths[1])
                        .addOffscreen("povDepthS", squashedDepths[2])
                        .addOffscreen("povDepthW", squashedDepths[3])
                    )
                )
            );

        this.pipelines = [
            this.context.createPipeline("main",
                new Screen(this.context, [1, 1, 1, 1, 1])
                    .addSubRenderer("globallighting", new GlobalLightingRenderer(this.context)
                        .addOffscreen("gbuffer", gbuffer)
                    )
                    .addSubRenderer("locallighting", new LocalLightingRenderer(this.context, entryHallLightVolumeMesh)
                        .addOffscreen("gbuffer", gbuffer)
                    )
                    .addSubRenderer("fowdarkening", new FOWDarkeningRenderer(this.context)
                        .addOffscreen("gbuffer", gbuffer)
                        .addOffscreen("fow", fow)
                    )
                    .addSubRenderer("lineofsight", new LineOfSightRenderer(this.context)
                        .addOffscreen("gbuffer", gbuffer)
                        .addOffscreen("povDepthN", squashedDepths[0])
                        .addOffscreen("povDepthE", squashedDepths[1])
                        .addOffscreen("povDepthS", squashedDepths[2])
                        .addOffscreen("povDepthW", squashedDepths[3])
                    )
                    .addSubRenderer("protagonist", new ProtagonistRenderer(this.context))
                ),

            this.context.createPipeline("depth",
                new Screen(this.context)
                    .addSubRenderer("debug", new DebugRenderer(this.context, debugModes.MONO, true)
                        .addOffscreen("reference", protagonistDepths[3])
                    )
                ),
            
            this.context.createPipeline("fow",
                new Screen(this.context)
                    .addSubRenderer("debug", new DebugRenderer(this.context, debugModes.MONO, false, 0, 0)
                        .addOffscreen("reference", fow)
                    )
                ),
            
            this.context.createPipeline("gbuffer-normal",
                new Screen(this.context)
                    .addSubRenderer("debug", new DebugRenderer(this.context, debugModes.RGB, false, 3)
                        .addOffscreen("reference", gbuffer)
                    )
                ),
            
            this.context.createPipeline("gbuffer-depth",
                new Screen(this.context)
                    .addSubRenderer("debug", new DebugRenderer(this.context, debugModes.MONO, false, 1, 3)
                        .addOffscreen("reference", gbuffer)
                    )
                ),
            
            this.context.createPipeline("lineOfSight",
                new Screen(this.context, [1, 1, 1, 1, 1])
                    .addSubRenderer("lineofsight", new LineOfSightRenderer(this.context)
                        .addOffscreen("gbuffer", gbuffer)
                        .addOffscreen("povDepthN", squashedDepths[0])
                        .addOffscreen("povDepthE", squashedDepths[1])
                        .addOffscreen("povDepthS", squashedDepths[2])
                        .addOffscreen("povDepthW", squashedDepths[3])
                    )
                ),
            
            this.context.createPipeline("globallighting",
                new Screen(this.context, [0, 0, 0, 1, 0.95])
                    .addSubRenderer("debug", new DebugRenderer(this.context, debugModes.RGB, false, 0)
                        .addOffscreen("reference", new Offscreen(this.context, [TEXTURE_TYPES.UBYTE_RGBA], true, [0, 0, 0, 1, 1.0])
                            .addSubRenderer("depthcopy", new DepthCopyRenderer(this.context)
                                .addOffscreen("source", gbuffer)
                            )
                            .addSubRenderer("locallighting", new LocalLightingRenderer(this.context, entryHallLightVolumeMesh)
                                .addOffscreen("gbuffer", gbuffer)
                            )
                        )
                    )
                )
        ];
        for (const pipeline of this.pipelines) {
            await pipeline.initAsync();
            pipeline.properties.scalars.povProjectionNear = povProjectionNear;
            pipeline.properties.scalars.povProjectionFar = povProjectionFar;
            pipeline.turnOff();
        }

        this.pipelines[0].turnOn();
    }

    start(frameCallback) {
        const viewTransform = new Transform()
            .queueOperation((delta, sinceStart, properties) => mat4.fromTranslation(mat4.create(), [-properties.protagonistPosition.x, -properties.protagonistPosition.y, -properties.protagonistPosition.z]))
            .queuePropertyZRotation(['viewRotation', 'x']).queuePropertyXRotation(['viewRotation', 'y']).queueFixedTranslation(0, 1, -1.5).queueFixedXRotation(-Math.PI/6);
        this.context.startAnimationLoop(frameInfo => {
            const protagonistPos = this.internalUpdateProtagonistPosition();
            const viewMatrix = viewTransform.getMatrix(frameInfo.timestampMS, this.properties);
            for (const pipeline of this.pipelines) {
                pipeline.properties.matrices.view = viewMatrix;
                pipeline.properties.positions.protagonist = protagonistPos;
            }
            frameCallback(frameInfo);
        });
    }

    toggleDebug() {
        for (const pipeline of this.pipelines) {
            pipeline.turnOff();
        }
        this.currentPipelineIndex = (this.currentPipelineIndex + 1) % this.pipelines.length;
        this.pipelines[this.currentPipelineIndex].turnOn();
    }

    internalUpdateProtagonistPosition() {
        const position = this.properties.protagonistPosition;
        const movement = this.properties.protagonistMovement;
        let { x, y, z } = position || { x: 1.5, y: 0.0, z: 0.2 };
        if (movement && (movement.x !== 0 || movement.y !== 0)) {
            const movementVector = this.internalTransformToWorldspace2DVector(vec3.fromValues(movement.x, movement.y, movement.z));
            x += movementVector[0];
            y += movementVector[1];
            z += 0;
            movement.x = 0;
            movement.y = 0;
            movement.z = 0;
            this.properties.protagonistPosition = { x, y, z };
        }
        return vec3.fromValues(x, y, z);
    }

    internalTransformToWorldspace2DVector(vector) {
        const magnitude = vec3.length(vector);
        const toWorldMatrix = mat4.invert(mat4.create(), this.pipelines[0].properties.matrices.view);
        const normalMatrix = mat3.normalFromMat4(mat3.create(), toWorldMatrix);
        const worldSpaceVector = vec3.transformMat3(vec3.create(), vector, normalMatrix);
        const worldSpace2DVector = vec2.fromValues(worldSpaceVector[0], worldSpaceVector[1]);
        vec2.normalize(worldSpace2DVector, worldSpace2DVector);
        vec2.scale(worldSpace2DVector, worldSpace2DVector, magnitude);
        return worldSpace2DVector;
    }
}