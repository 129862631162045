import { Renderer, Offscreen } from '@ancienttech/glass';
import { mat4 } from 'gl-matrix';

export default class DepthSquasherRenderer extends Renderer {
    constructor(context, inputWidth, inputHeight) {
        super(context);
        if (Math.log2(inputHeight) % 1 !== 0) {
            throw new Error(`Input height should be a power of 2: ${inputHeight}`);
        }
        this.inputHeight = inputHeight;
        this.inputWidth = inputWidth;
    }

    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(require('../shaders/imageprocessingquad.vert'), require('../shaders/depthsquasher.frag'),
            ["in_position"],
            ["u_inputTexture", "u_scalingMatrix"]);
        this.vao = this.context.getUnitQuadMesh().makeDrawVao({ positions: this.program.attributes.in_position });

        this.doubleBuffer = [];
        this.doubleBuffer.push(new Offscreen(this.context, [], true, undefined, this.inputWidth, this.inputHeight));
        this.doubleBuffer.push(new Offscreen(this.context, [], true, undefined, this.inputWidth, this.inputHeight));
        await this.doubleBuffer[0].initAsync();
        await this.doubleBuffer[1].initAsync();
        this.addOffscreen("one", this.doubleBuffer[0]);
        this.addOffscreen("two", this.doubleBuffer[1]);

        this.halveTransform = mat4.fromScaling(mat4.create(), [1.0, 0.5, 1.0]);
    }

    destroy() {
        super.destroy();
        this.vao.destroy();
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        const GL = this.context.gl;

        let inputTexture = this.handleDepth(frameInfo, properties);

        GL.disable(GL.CULL_FACE);
        GL.depthFunc(GL.ALWAYS);

        GL.useProgram(this.program.program);
        
        let currentBuffer = 0;
        let transform = this.halveTransform;
        const times = Math.log2(this.inputHeight);
        for (let i = 0; i < times; i++) {
            this.context.bindTextures([inputTexture], [this.program.uniforms.u_inputTexture]);
            GL.uniformMatrix4fv(this.program.uniforms.u_scalingMatrix, false, transform);
            this.doubleBuffer[currentBuffer].mountBufferFunc();
            this.vao.draw();
            inputTexture = this.doubleBuffer[currentBuffer].getTextures().depth;
            currentBuffer = 1 - currentBuffer;
            transform = mat4.multiply(mat4.create(), this.halveTransform, transform);
        }
        
        mountBufferFunc();
        this.doubleBuffer[1-currentBuffer].blitToCurrentFramebuffer(GL.DEPTH_BUFFER_BIT);

        GL.depthFunc(GL.LESS);
    }

    handleDepth(frameInfo, properties) {
        const depthOffscreen = this.getOffscreen("depth");
        if (!depthOffscreen) {
            throw new Error(`${this.constructor.name} requires a 'depth' offscreen`);
        }

        const depth = depthOffscreen.render(frameInfo, properties);
        if (!depth.depth) {
            throw new Error(`${this.constructor.name} requires a depth texture from the 'depth' offscreen.`);
        }

        return depth.depth;
    }
}