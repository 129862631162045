import { Renderer, Transform } from '@ancienttech/glass';
import { vec3, mat4 } from 'gl-matrix';

export default class LocalLightingRenderer extends Renderer {
    constructor(context, lightVolumeMesh) {
        super(context);
        this.lightVolumeMesh = lightVolumeMesh;
    }

    async customInitAsync() {
        this.lightProgram = await this.context.loadProgramAsync(this.context.shaders.vertex.position, require('../../shaders/lighting/locallighting.frag'),
            ["in_position", "in_textureCoord"],
            ["u_materialTexture", "u_positionTexture_vw", "u_normalTexture_vw", "u_bufferSize", "u_light.pos_vw", "u_light.color", "u_light.falloff", "u_modelMatrix", "u_viewMatrix", "u_projectionMatrix"]);
        this.volumeProgram = await this.context.loadProgramAsync(this.context.shaders.vertex.position, this.context.shaders.fragment.empty,
            ["in_position"],
            ["u_modelMatrix", "u_viewMatrix", "u_projectionMatrix"]);
        
        this.lightVolumeVao = this.lightVolumeMesh.makeDrawVao({ positions: this.volumeProgram.attributes.in_position });

        this.lights = [
            {
                positionTransform: new Transform().queueFixedTranslation(0.25, 0.72, 0.3),
                color: [1.0, 1.0, 0.8],
                falloff: [1.0, 1.8, 3.0],
                volume: this.lightVolumeVao
            },
            {
                positionTransform: new Transform().queueFixedTranslation(0.25, -0.72, 0.3),
                color: [1.0, 1.0, 0.8],
                falloff: [1.0, 1.8, 3.0],
                volume: this.lightVolumeVao
            },
            {
                positionTransform: new Transform().queueFixedTranslation(-0.3, 0.72, 0.3),
                color: [1.0, 1.0, 0.8],
                falloff: [1.0, 1.8, 3.0],
                volume: this.lightVolumeVao
            },
            {
                positionTransform: new Transform().queueFixedTranslation(-0.3, -0.72, 0.3),
                color: [1.0, 1.0, 0.8],
                falloff: [1.0, 1.8, 3.0],
                volume: this.lightVolumeVao
            },
            {
                positionTransform: new Transform().queueFixedTranslation(-1.4, 0.0, 0.3),
                color: [0.9, 0.8, 1.0],
                falloff: [1.0, 1.8, 3.0],
                volume: this.lightVolumeVao
            }
        ];
    }

    destroy() {
        super.destroy();
        this.lightProgram.destroy();
        this.lightVolumeVao.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        const GL = this.context.gl;

        const gbuffer = this.handleGBuffer(frameInfo, properties);

        mountBufferFunc();
        GL.blendEquation(GL.FUNC_ADD);
        GL.blendFunc(GL.ONE, GL.ONE);
        GL.enable(GL.BLEND);
        GL.enable(GL.STENCIL_TEST);
        GL.depthMask(false);
        GL.cullFace(GL.FRONT);

        for (let light of this.lights) {
            // Stencil pass
            GL.colorMask(false, false, false, false);
            GL.stencilMask(0xFF);
            GL.enable(GL.DEPTH_TEST);
            GL.disable(GL.CULL_FACE);
            GL.stencilFunc(GL.ALWAYS, 0, 0);
            GL.stencilOpSeparate(GL.BACK, GL.KEEP, GL.INCR_WRAP, GL.KEEP);
            GL.stencilOpSeparate(GL.FRONT, GL.KEEP, GL.DECR_WRAP, GL.KEEP);
            GL.clear(GL.STENCIL_BUFFER_BIT);
            GL.useProgram(this.volumeProgram.program);
            GL.uniformMatrix4fv(this.volumeProgram.uniforms.u_viewMatrix, false, properties.matrices.view);
            GL.uniformMatrix4fv(this.volumeProgram.uniforms.u_projectionMatrix, false, properties.matrices.projection);
            GL.uniformMatrix4fv(this.volumeProgram.uniforms.u_modelMatrix, false, mat4.create());

            light.volume.draw();

            // Light pass
            GL.colorMask(true, true, true, true);
            GL.stencilMask(0);
            GL.disable(GL.DEPTH_TEST);
            GL.enable(GL.CULL_FACE);
            GL.stencilFunc(GL.NOTEQUAL, 0, 0xFF);
            GL.useProgram(this.lightProgram.program);
            GL.uniformMatrix4fv(this.lightProgram.uniforms.u_viewMatrix, false, properties.matrices.view);
            GL.uniformMatrix4fv(this.lightProgram.uniforms.u_projectionMatrix, false, properties.matrices.projection);
            GL.uniformMatrix4fv(this.lightProgram.uniforms.u_modelMatrix, false, mat4.create());
            this.context.bindTextures(
                [gbuffer.textures[0], gbuffer.textures[1], gbuffer.textures[3]],
                [
                    this.lightProgram.uniforms.u_materialTexture,
                    this.lightProgram.uniforms.u_positionTexture_vw,
                    this.lightProgram.uniforms.u_normalTexture_vw
                ]
            );

            this.bindLight(light, frameInfo, properties);
            light.volume.draw();
        }

        GL.depthMask(true);
        GL.colorMask(true, true, true, true);
        GL.disable(GL.BLEND);
        GL.disable(GL.STENCIL_TEST);
    }

    handleGBuffer(frameInfo, properties) {
        const gbufferOffscreen = this.getOffscreen("gbuffer");
        if (!gbufferOffscreen) {
            throw new Error(`${this.constructor.name} requires an 'gbuffer' offscreen`);
        }
        const textures = gbufferOffscreen.render(frameInfo, properties);
        if (textures.colors.length < 4) {
            throw new Error(`${this.constructor.name} requires at least 4 textures from the 'gbuffer' offscreen. Was ${textures.colors.length}.`);
        }

        return { textures: textures.colors };
    }

    bindLight(light, frameInfo, properties) {
        const GL = this.context.gl;
        const transformMatrix = mat4.multiply(mat4.create(), properties.matrices.view, light.positionTransform.getMatrix(frameInfo.timestampMS, properties));
        GL.uniform3fv(this.lightProgram.uniforms["u_light.pos_vw"], vec3.transformMat4(vec3.create(), vec3.create(), transformMatrix));
        GL.uniform3fv(this.lightProgram.uniforms["u_light.color"], light.color);
        GL.uniform1fv(this.lightProgram.uniforms["u_light.falloff"], light.falloff);
    }
}