import { Renderer } from '@ancienttech/glass';
import { mat4 } from 'gl-matrix';

export default class FOWClearerRenderer extends Renderer {
    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(require('../shaders/imageprocessingquad.vert'), require('../shaders/fowclearer.frag'),
            ["in_position"],
            ["u_inputTexture", "u_scalingMatrix"]);
        this.vao = this.context.getUnitQuadMesh().makeDrawVao({ positions: this.program.attributes.in_position });
        this.transform = mat4.create();
    }

    destroy() {
        super.destroy();
        this.vao.destroy();
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        const GL = this.context.gl;

        let inputTexture = this.handleInput(frameInfo, properties);

        GL.disable(GL.CULL_FACE);
        GL.disable(GL.DEPTH_TEST);

        GL.useProgram(this.program.program);
        
        this.context.bindTextures([inputTexture],[this.program.uniforms.u_inputTexture]);
        GL.uniformMatrix4fv(this.program.uniforms.u_scalingMatrix, false, this.transform);

        mountBufferFunc();
        this.vao.draw();

        GL.enable(GL.DEPTH_TEST);
    }

    handleInput(frameInfo, properties) {
        const inputOffscreen = this.getOffscreen("input");
        if (!inputOffscreen) {
            throw new Error(`${this.constructor.name} requires an 'input' offscreen`);
        }

        const input = inputOffscreen.render(frameInfo, properties);
        if (input.colors.length < 1) {
            throw new Error(`${this.constructor.name} requires at least 1 texture from the 'input' offscreen. Was ${input.colors.length}`);
        }

        return input.colors[0];
    }
}