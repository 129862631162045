import { Renderer } from "@ancienttech/glass";
import { mat4 } from "gl-matrix";

export default class ProtagonistDepthRenderer extends Renderer {
    constructor(context, mesh, cameraTransforms) {
        super(context);
        this.mesh = mesh;
        this.cameraTransforms = cameraTransforms;
    }

    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(require('../shaders/protagonistdepth.vert'), require('../shaders/protagonistdepth.frag'), ["in_position"], ["u_modelMatrix", "u_viewMatrix", "u_projectionMatrix", "u_farPlane"]);

        this.vao = this.mesh.makeDrawVao({ positions: this.program.attributes.in_position });
    }

    destroy() {
        super.destroy();
        this.vao.destroy();
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        //matrices = Object.create(matrices);
        const pointOfViewMatrices = this.getPointOfViewMatrices(frameInfo, properties);

        const GL = this.context.gl;
        mountBufferFunc();
        
        GL.enable(GL.CULL_FACE);
        GL.enable(GL.DEPTH_TEST);
        GL.cullFace(GL.FRONT);

        GL.useProgram(this.program.program);

        GL.uniformMatrix4fv(this.program.uniforms.u_viewMatrix, false, pointOfViewMatrices.view);
        GL.uniformMatrix4fv(this.program.uniforms.u_projectionMatrix, false, pointOfViewMatrices.projection);
        GL.uniformMatrix4fv(this.program.uniforms.u_modelMatrix, false, mat4.create());
        GL.uniform1f(this.program.uniforms.u_farPlane, properties.scalars.povProjectionFar);
        
        this.vao.draw();

        GL.cullFace(GL.BACK);
    }

    getPointOfViewMatrices(frameInfo, properties) {
        return {
            view: this.cameraTransforms.view.getMatrix(frameInfo.timestampMS, properties),
            projection: this.cameraTransforms.projection.getMatrix(frameInfo.timestampMS, properties)
        };
    }

    query(query) {
        if (query === "PointOfViewMatricesFunc") {
            return this.getPointOfViewMatrices.bind(this);
        }
    }
}