import { Renderer } from '@ancienttech/glass';
import { vec3 } from 'gl-matrix';

export default class LineOfSightRenderer extends Renderer {
    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(this.context.shaders.vertex.fullscreenquad, require('../../shaders/lighting/lineofsight.frag'),
            ["in_position", "in_textureCoord"],
            ["u_positionTexture_vw", "u_positionTexture_wd", "u_normalTexture_vw", "u_povDepthTextureN", "u_povDepthTextureE", "u_povDepthTextureS", "u_povDepthTextureW", "u_blockedPatternTexture", "u_protagonistPosition_wd", "u_protagonistPosition_vw", "u_farPlane"]);
        this.textures = await this.context.loadTexturesAsync([
            {url:require('../../textures/blocked-line-of-sight-pattern.png'), minFilter:this.context.gl.NEAREST, wrap:this.context.gl.REPEAT}
        ]);
        this.vao = this.context.getUnitQuadMesh().makeDrawVao({ positions: this.program.attributes.in_position, colorUV: this.program.attributes.in_textureCoord });
    }

    destroy() {
        super.destroy();
        this.vao.destroy();
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        const GL = this.context.gl;

        const gbuffer = this.handleGBuffer(frameInfo, properties);
        const povDepthTextures = this.handlePOVDepth(frameInfo, properties);

        mountBufferFunc();
        GL.disable(GL.CULL_FACE);
        GL.disable(GL.DEPTH_TEST);
        GL.enable(GL.BLEND);
        GL.blendEquation(GL.FUNC_ADD);
        GL.blendFunc(GL.DST_COLOR, GL.ONE_MINUS_DST_COLOR);

        GL.useProgram(this.program.program);
        this.context.bindTextures(
            [gbuffer.textures[1], gbuffer.textures[2], gbuffer.textures[3], ...povDepthTextures, ...this.textures],
            [
                this.program.uniforms.u_positionTexture_vw,
                this.program.uniforms.u_positionTexture_wd,
                this.program.uniforms.u_normalTexture_vw,
                this.program.uniforms.u_povDepthTextureN,
                this.program.uniforms.u_povDepthTextureE,
                this.program.uniforms.u_povDepthTextureS,
                this.program.uniforms.u_povDepthTextureW,
                this.program.uniforms.u_blockedPatternTexture
            ]
        );
        GL.uniform3fv(this.program.uniforms.u_protagonistPosition_wd, properties.positions.protagonist);
        GL.uniform3fv(this.program.uniforms.u_protagonistPosition_vw, vec3.transformMat4(vec3.create(), properties.positions.protagonist, properties.matrices.view));
        GL.uniform1f(this.program.uniforms.u_farPlane, properties.scalars.povProjectionFar);

        this.vao.draw();

        GL.disable(GL.BLEND);
    }

    handleGBuffer(frameInfo, properties) {
        const gbufferOffscreen = this.getOffscreen("gbuffer");
        if (!gbufferOffscreen) {
            throw new Error(`${this.constructor.name} requires a 'gbuffer' offscreen`);
        }
        const textures = gbufferOffscreen.render(frameInfo, properties);
        if (textures.colors.length < 4) {
            throw new Error(`${this.constructor.name} requires at least 4 textures from the 'gbuffer' offscreen. Was ${textures.colors.length}`);
        }

        return { textures: textures.colors };
    }

    handlePOVDepth(frameInfo, properties) {
        const povNOffscreen = this.getOffscreen("povDepthN");
        const povEOffscreen = this.getOffscreen("povDepthE");
        const povSOffscreen = this.getOffscreen("povDepthS");
        const povWOffscreen = this.getOffscreen("povDepthW");
        if (!povNOffscreen || !povEOffscreen || !povSOffscreen || !povWOffscreen) {
            throw new Error(`${this.constructor.name} requires the following offscreens: povDepthN, povDepthE, povDepthS, povDepthW`);
        }

        const results = [
            povNOffscreen.render(frameInfo, properties),
            povEOffscreen.render(frameInfo, properties),
            povSOffscreen.render(frameInfo, properties),
            povWOffscreen.render(frameInfo, properties),
        ];

        if (results.filter(r => r.depth).length !== 4) {
            throw new Error(`${this.constructor.name} requires a depth texture for each of the povDepth* offscreens.`);
        }

        return results.map(r => r.depth);
    }
}