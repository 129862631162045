import { Renderer } from '@ancienttech/glass';

export default class GlobalLightingRenderer extends Renderer {
    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(this.context.shaders.vertex.fullscreenquad, require('../../shaders/lighting/globallighting.frag'),
            ["in_position", "in_textureCoord"],
            ["u_materialTexture", "u_positionTexture_vw", "u_positionTexture_wd", "u_normalTexture_vw"]);
        this.vao = this.context.getUnitQuadMesh().makeDrawVao({ positions: this.program.attributes.in_position, colorUV: this.program.attributes.in_textureCoord });
    }

    destroy() {
        super.destroy();
        this.vao.destroy();
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        const GL = this.context.gl;

        const gbuffer = this.handleGBuffer(frameInfo, properties);

        mountBufferFunc();
        GL.disable(GL.CULL_FACE);
        GL.enable(GL.DEPTH_TEST);

        GL.useProgram(this.program.program);
        this.context.bindTextures(
            [...gbuffer.textures],
            [
                this.program.uniforms.u_materialTexture,
                this.program.uniforms.u_positionTexture_vw,
                this.program.uniforms.u_positionTexture_wd,
                this.program.uniforms.u_normalTexture_vw
            ]
        );

        this.vao.draw();
    }

    handleGBuffer(frameInfo, properties) {
        const gbufferOffscreen = this.getOffscreen("gbuffer");
        if (!gbufferOffscreen) {
            throw new Error(`${this.constructor.name} requires a 'gbuffer' offscreen`);
        }
        const textures = gbufferOffscreen.render(frameInfo, properties);
        if (textures.colors.length < 4) {
            throw new Error(`${this.constructor.name} requires at least 4 textures from the 'gbuffer' offscreen. Was ${textures.colors.length}`);
        }

        return { textures: textures.colors };
    }
}