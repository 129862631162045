import { Renderer } from '@ancienttech/glass';

export default class FOWDarkeningRenderer extends Renderer {
    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(this.context.shaders.vertex.fullscreenquad, require('../../shaders/lighting/fowdarkening.frag'),
            ["in_position", "in_textureCoord"],
            ["u_positionTexture_wd", "u_fowTexture"]);
        this.vao = this.context.getUnitQuadMesh().makeDrawVao({ positions: this.program.attributes.in_position, colorUV: this.program.attributes.in_textureCoord });
    }

    destroy() {
        super.destroy();
        this.vao.destroy();
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        const GL = this.context.gl;

        const gbuffer = this.handleGBuffer(frameInfo, properties);
        const fowTexture = this.handleFOW(frameInfo, properties);

        mountBufferFunc();
        GL.disable(GL.CULL_FACE);
        GL.disable(GL.DEPTH_TEST);
        GL.enable(GL.BLEND);
        GL.blendEquation(GL.FUNC_ADD);
        GL.blendFunc(GL.DST_COLOR, GL.ZERO);


        GL.useProgram(this.program.program);
        this.context.bindTextures(
            [gbuffer.textures[2], fowTexture],
            [
                this.program.uniforms.u_positionTexture_wd,
                this.program.uniforms.u_fowTexture,
            ]
        );

        this.vao.draw();

        GL.disable(GL.BLEND);
    }

    handleGBuffer(frameInfo, properties) {
        const gbufferOffscreen = this.getOffscreen("gbuffer");
        if (!gbufferOffscreen) {
            throw new Error(`${this.constructor.name} requires an 'gbuffer' offscreen`);
        }
        const textures = gbufferOffscreen.render(frameInfo, properties);
        if (textures.colors.length < 4) {
            throw new Error(`${this.constructor.name} requires at least 4 textures from the 'gbuffer' offscreen. Was ${textures.colors.length}.`);
        }

        return { textures: textures.colors };
    }

    handleFOW(frameInfo, properties) {
        const fowOffscreen = this.getOffscreen("fow");
        if (!fowOffscreen) {
            throw new Error(`${this.constructor.name} requires a 'fow' offscreen`);
        }
        const textures = fowOffscreen.render(frameInfo, properties);
        if (textures.colors.length < 1) {
            throw new Error(`${this.constructor.name} requires at least 1 texture from the 'fow' offscreen. Was ${textures.colors.length}.`);
        }

        return textures.colors[0];
    }
}