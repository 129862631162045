import { Renderer } from '@ancienttech/glass';

export default class FOWRenderer extends Renderer {
    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(require('../shaders/fow.vert'), require('../shaders/fow.frag'),
            ["in_position", "in_textureCoord"],
            ["u_povDepthTextureN", "u_povDepthTextureE", "u_povDepthTextureS", "u_povDepthTextureW", "u_farPlane", "u_protagonistPosition_wd"]);
        this.vao = this.context.getUnitQuadMesh().makeDrawVao({ positions: this.program.attributes.in_position, colorUV: this.program.attributes.in_textureCoord });
    }

    destroy() {
        super.destroy();
        this.vao.destroy();
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        const GL = this.context.gl;

        const povDepthTextures = this.handlePOVDepth(frameInfo, properties);

        mountBufferFunc();
        GL.disable(GL.CULL_FACE);
        GL.disable(GL.DEPTH_TEST);
        GL.enable(GL.BLEND);
        GL.blendEquation(GL.MAX);
        GL.blendFunc(GL.ONE, GL.ONE);

        GL.useProgram(this.program.program);
        this.context.bindTextures(povDepthTextures, [this.program.uniforms.u_povDepthTextureN, this.program.uniforms.u_povDepthTextureE, this.program.uniforms.u_povDepthTextureS, this.program.uniforms.u_povDepthTextureW]);
        GL.uniform1f(this.program.uniforms.u_farPlane, properties.scalars.povProjectionFar);
        GL.uniform3fv(this.program.uniforms.u_protagonistPosition_wd, properties.positions.protagonist);

        this.vao.draw();

        GL.enable(GL.DEPTH_TEST);
        GL.disable(GL.BLEND);
        GL.blendEquation(GL.FUNC_ADD);
    }

    handlePOVDepth(frameInfo, properties) {
        const povNOffscreen = this.getOffscreen("povDepthN");
        const povEOffscreen = this.getOffscreen("povDepthE");
        const povSOffscreen = this.getOffscreen("povDepthS");
        const povWOffscreen = this.getOffscreen("povDepthW");
        if (!povNOffscreen || !povEOffscreen || !povSOffscreen || !povWOffscreen) {
            throw new Error(`${this.constructor.name} requires the following offscreens: povDepthN, povDepthE, povDepthS, povDepthW`);
        }

        const results = [
            povNOffscreen.render(frameInfo, properties),
            povEOffscreen.render(frameInfo, properties),
            povSOffscreen.render(frameInfo, properties),
            povWOffscreen.render(frameInfo, properties),
        ];

        if (results.filter(r => r.depth).length !== 4) {
            throw new Error(`${this.constructor.name} requires a depth texture for each of the povDepth* offscreens.`);
        }

        return results.map(r => r.depth);
    }
}