import { Renderer } from '@ancienttech/glass';

export default class SimpleObjectRenderer extends Renderer {
    constructor(context, transform, textureUrl, objFileUrl) {
        super(context);
        this.transform = transform;
        this.textureUrl = textureUrl;
        this.objFileUrl = objFileUrl;
    }

    async customInitAsync() {
        this.program = await this.context.loadProgramAsync(require('../shaders/simple.vert'), require('../shaders/simple.frag'), ["in_position", "in_textureCoord", "in_normal"], ["u_texture0", "u_modelMatrix", "u_viewMatrix", "u_projectionMatrix"]);
        this.textures = await this.context.loadTexturesAsync([{url:this.textureUrl, magFilter:this.context.gl.LINEAR}]);
        this.vao = (await this.context.loadWavefrontObject(this.objFileUrl)).makeDrawVao({
            positions: this.program.attributes.in_position,
            colorUV: this.program.attributes.in_textureCoord,
            normals: this.program.attributes.in_normal
        });
    }

    destroy() {
        super.destroy();
        const GL = this.context.gl;
        this.vao.destroy();
        for (let texture of this.textures) {
            GL.deleteTexture(texture);
        }
        this.program.destroy();
    }

    customRender(frameInfo, mountBufferFunc, properties) {
        mountBufferFunc();

        const GL = this.context.gl;
        GL.enable(GL.CULL_FACE);
        GL.enable(GL.DEPTH_TEST);

        GL.useProgram(this.program.program);
        this.context.bindTextures(this.textures, [this.program.uniforms.u_texture0]);

        GL.uniformMatrix4fv(this.program.uniforms.u_viewMatrix, false, properties.matrices.view);
        GL.uniformMatrix4fv(this.program.uniforms.u_projectionMatrix, false, properties.matrices.projection);
        GL.uniformMatrix4fv(this.program.uniforms.u_modelMatrix, false, this.customModelMatrix(frameInfo, properties));

        this.vao.draw();
    }

    customModelMatrix(frameInfo, properties) {
        return this.transform.getMatrix(frameInfo.timestampMS, properties);
    }
}