import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import GLEngine from './gl/gl-engine'

const renderHUD = fps => {
    ReactDOM.render(<App fps={fps} />, document.getElementById("main-ui"));
};
renderHUD(0);

const glEngine = new GLEngine(document.getElementById("main-canvas"));

let keyboardXRotation = 0.0;
let keyboardYRotation = 0.0;
let keyboardXMovement = 0.0;
let keyboardYMovement = 0.0;
let gamepadButtonAlreadyPressed = false;

(async () => {
    try {
        await glEngine.initAsync();
        let lastFrame = Date.now();
        let lastFpsUpdate = Date.now();
        let framesRendered = 0;
        glEngine.properties.viewRotation = { x: -1.0, y: 0.0 };
        glEngine.properties.protagonistPosition = { x: 1.5, y: 0, z: 0.2 };
        glEngine.properties.protagonistMovement = { x: 0, y: 0, z: 0 };
        glEngine.start(frameInfo => {
            framesRendered += 1;
            const sampleDuration = frameInfo.timestampMS - lastFpsUpdate;
            if (sampleDuration > 5000) {
                renderHUD(Math.round(framesRendered * 1000 / sampleDuration));
                lastFpsUpdate = frameInfo.timestampMS;
                framesRendered = 0;
            }
            const frameDelta = frameInfo.timestampMS - lastFrame;
            lastFrame = frameInfo.timestampMS;
            glEngine.properties.viewRotation.x -= keyboardXRotation * frameDelta * 0.002;
            glEngine.properties.viewRotation.y += keyboardYRotation * frameDelta * 0.002;
            glEngine.properties.protagonistMovement.x += keyboardXMovement * frameDelta * 0.002;
            glEngine.properties.protagonistMovement.y += keyboardYMovement * frameDelta * 0.002;
            for (const pad of navigator.getGamepads()) {
                if (pad !== null && pad.axes.length >= 3) {
                    glEngine.properties.viewRotation.x -= Math.sign(pad.axes[2]) * Math.max(Math.abs(pad.axes[2]) - 0.15, 0.0) * frameDelta * 0.005;
                    glEngine.properties.viewRotation.y -= Math.sign(pad.axes[3]) * Math.max(Math.abs(pad.axes[3]) - 0.15, 0.0) * frameDelta * 0.005;
                    glEngine.properties.protagonistMovement.x += Math.sign(pad.axes[0]) * Math.max(Math.abs(pad.axes[0]) - 0.15, 0.0) * frameDelta * 0.002;
                    glEngine.properties.protagonistMovement.y -= Math.sign(pad.axes[1]) * Math.max(Math.abs(pad.axes[1]) - 0.15, 0.0) * frameDelta * 0.002;
                    if (pad.buttons[0]) {
                        if (pad.buttons[0].pressed) {
                            if (!gamepadButtonAlreadyPressed) {
                                glEngine.toggleDebug();
                                gamepadButtonAlreadyPressed = true;
                                console.log(pad);
                            }
                        } else {
                            gamepadButtonAlreadyPressed = false;
                        }
                    }
                }
            }
        });
    } catch (e) {
        alert("Failed to initialize application. See console log");
        console.log(e);
    }
})();

document.onkeydown = ev => {
    switch (ev.key) {
        case " ": glEngine.toggleDebug(); break;
        case "ArrowUp": keyboardYRotation = 1.0; break;
        case "ArrowDown": keyboardYRotation = -1.0; break;
        case "ArrowRight": keyboardXRotation = 1.0; break;
        case "ArrowLeft": keyboardXRotation = -1.0; break;
        case "w": keyboardYMovement = 1.0; break;
        case "s": keyboardYMovement = -1.0; break;
        case "d": keyboardXMovement = 1.0; break;
        case "a": keyboardXMovement = -1.0; break;
        default: break;
    }
}

document.onkeyup = ev => {
    keyboardXRotation = 0.0;
    keyboardYRotation = 0.0;
    keyboardXMovement = 0.0;
    keyboardYMovement = 0.0;
}